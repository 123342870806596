import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useMount } from 'react-use'

import { parseACF, getGlobalData } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, innerContainer, padding, bgImage, colours, type } from '../styles/global'
import { headerHeight, mobHeaderHeight } from '../components/Header/Header'

import { IsMobileContext } from '../context/IsMobileContext'
import { ThemeContext } from '../context/ThemeContext'

const Contact = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const { global: global_data } = getGlobalData()
    const { isMobile } = useContext(IsMobileContext)
    const theme = useContext(ThemeContext)
    console.log(data)

    useMount(() => {
        theme.setTheme({
            headerColour: colours.peach,
            bgColour: colours.blue,
            footerColour: colours.peach
        })
    })

    const renderAddress = () => {
        if (!global_data.contact_address) return

        return (
            <Address>
                <Description
                    dangerouslySetInnerHTML={{
                        __html: global_data.contact_address,
                    }}
                />
            </Address>
        )
    }

    const renderEmail = () => {
        if (!global_data.contact_email) return

        return (
            <Email>
                <ExternalLink
                    href={`mailto:${global_data.contact_email}`}
                >
                    {global_data.contact_email}
                </ExternalLink>
            </Email>
        )
    }

    const renderPhone = () => {
        if (!global_data.contact_phone) return

        return (
            <Phone>
                <ExternalLink
                    href={`tel:${global_data.contact_phone}`}
                >
                    {global_data.contact_phone}
                </ExternalLink>
            </Phone>
        )
    }

    const renderFollow = () => {
        if (!global_data.contact_follow) return
        
        return (
            <SocialLinks>
                {global_data.contact_follow.instagram && (
                    <ExternalLink
                        href={global_data.contact_follow.instagram}
                        target={'_blank'}
                    >
                        instagram
                    </ExternalLink>
                )}
                {global_data.contact_follow.facebook && (
                    <ExternalLink
                        href={global_data.contact_follow.facebook}
                        target={'_blank'}
                    >
                        facebook
                    </ExternalLink>
                )}
                {global_data.contact_follow.linkedin && (
                    <ExternalLink
                        href={global_data.contact_follow.linkedin}
                        target={'_blank'}
                    >
                        linkedin
                    </ExternalLink>
                )}
            </SocialLinks>
        )
    }


    return (
        <Layout
            meta={data && data.seo}
            hideFooter={true}
        >
            <Wrapper>
                <Container>
                    <Intro
                        dangerouslySetInnerHTML={{
                            __html: data.contact_intro,
                        }}
                    />
                    <Grid>
                        <Col>
                            <Heading>Visit</Heading>
                            {renderAddress()}
                        </Col>
                        <Col>
                            <Heading>Email</Heading>
                            {renderEmail()}
                        </Col>
                        <Col>
                            <Heading>Follow</Heading>
                            {renderFollow()}
                        </Col>
                        <Col>
                            {/* <Heading>Call</Heading> */}
                            {/* {renderPhone()} */}
                        </Col>
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Description = styled.div``
const ExternalLink = styled.a``
const Item = styled.div``


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight}px;
    padding-bottom: 6rem;

    ${media.mob`
        padding-top: ${mobHeaderHeight}px;
    `}
`

const Container = styled.div`
    ${container}
    ${innerContainer}
    ${padding}

    @media (min-width: 1280px) {
        padding-left: 170px;
        padding-right: 170px;
    }
`

// Introduction

const Intro = styled.div`
    width: 54rem;
    max-width: 100%;
    padding-top: 9rem;
    padding-bottom: 8rem;

    &, * {
        ${tw`font-display text-peach`};
        font-weight: 300;
        font-size: 2.25rem;
        line-height: 1.19;
    }

    > *:not(:first-child) {
        margin-top: 1em;
    }
`

// Contact Info

const Col = styled.div``
const ColContainer = styled.div``
const Grid = styled.div`
    ${tw`flex justify-between w-full -mx-2`};

    ${media.mob`
        ${tw`block`};
    `}

    ${Col} {
        ${tw`mx-2`};

        &:not(:first-child) {
            ${media.mob`
                margin-top: 3rem;
            `}
        }

        ${Heading} {
            ${type.heading}
            ${tw`text-peach`};
            margin-bottom: 2.5rem;

            ${media.mob`
                margin-bottom: 1.5rem;
            `}
        }
    }
`


const textStyles = css`
    ${tw`font-body text-peach`};
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.67;
    letter-spacing: 0.05em;    
`

const Address = styled.div`
    ${textStyles}
`

const Email = styled.div`
    ${ExternalLink} {
        ${textStyles}
        text-decoration: underline;
    }
`

const Phone = styled.div`
    ${ExternalLink} {
        ${textStyles}
        text-decoration: underline;
    }
`

// Social Links

const SocialLinks = styled.div`
    ${tw`flex flex-col items-start`};

    ${ExternalLink} {
        ${textStyles}
        text-decoration: underline;
    }
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Contact
